<template>
    <div class="alert-message">
        <remote-content
            :value="item.message"
            :ticket-data="{
                type: 'Other',
                subject: item.alert_type_name
            }" />
    </div>
</template>

<script>
import RemoteContent from '@/components/globals/RemoteContent';

export default {
    components: {
        RemoteContent
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.alert-message {
    font-size: 11px;
}
</style>